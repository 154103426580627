// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/baribuilder-ui/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/baribuilder-ui/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/baribuilder-ui/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-tag-js": () => import("/opt/build/repo/baribuilder-ui/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-author-js": () => import("/opt/build/repo/baribuilder-ui/src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/baribuilder-ui/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/baribuilder-ui/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("/opt/build/repo/baribuilder-ui/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sign-up-thank-you-js": () => import("/opt/build/repo/baribuilder-ui/src/pages/sign-up-thank-you.js" /* webpackChunkName: "component---src-pages-sign-up-thank-you-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/baribuilder-ui/.cache/data.json")

